<template>
  <div class="image-container">
    <div class="image" :class="{ selected : selectedImageIndex === index }" v-if="!isVideo()">
      <img :src="imagePreview" @error="imageErrorHandler" alt="Image Preview">
    </div>
    <div v-else class="image" :class="{ selected : selectedImageIndex === index, videoContainer: isVideo() }">
      <video autoplay muted loop playsinline>
        <source :src="imagePreview" type="video/mp4">
      </video>
    </div>

    <FontAwesomeIcon v-if="!editable && !confirmDelete && !confirmPrimary" class="delete-icon mx-2" color="#FF5A5F" :icon="['fas', 'times-circle']" @click="confirmDelete = true"/>
    <FontAwesomeIcon v-if="!editable && !confirmPrimary && selectedImageIndex !== index && !confirmDelete" class="set-primary-icon" color="#79E766" :icon="['fas', 'check-circle']" @click="confirmPrimary = true"/>

    <div class="image-label primary" v-if="selectedImageIndex === index">{{$t('Primary Image')}}</div>
    <div class="image-label planimetry" v-if="planimetryIndex === index">{{$t('Planimetry')}}</div>

    <transition name="fade">
      <div class="confirm-delete" v-if="!editable && confirmDelete">
        <RFButton size="small" background="#fff" color="#24425B" label="Confirm" @on-click="deleteImage" v-if="!loading"/>
        <RFButton class="ml-2" size="small" background="#fff" color="#24425B" label="Cancel" @on-click="confirmDelete = false" v-if="!loading"/>
        <RFLoader color="#fff" v-if="loading"/>
      </div>
      <div class="confirm-delete" v-if="!editable && confirmPrimary">
        <RFButton size="small" background="#fff" color="#24425B" label="Confirm" @on-click="editImage" v-if="!loading"/>
        <RFButton class="ml-2" size="small" background="#fff" color="#24425B" label="Cancel" @on-click="confirmPrimary = false" v-if="!loading"/>
        <RFLoader color="#fff" v-if="loading"/>
      </div>
    </transition>
    <div class="hover" v-if="editable">
      <FontAwesomeIcon class="action-icon mx-2" color="#fff" size="lg" :icon="['fas', 'check']" @click="selectImage" v-if="isAccommodation"/>
      <FontAwesomeIcon class="action-icon mx-2" color="#fff" size="lg" :icon="['fas', 'trash']" @click="removeImage"/>
      <FontAwesomeIcon class="action-icon mx-2" color="#fff" size="lg" :icon="['fas', 'home']" @click="selectPlanimetry" v-if="isAccommodation"/>
    </div>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'
import { getAsset } from '@/helpers'

export default {
  name: 'AdminImage',
  components: {
    RFButton,
    RFLoader,
  },
  props: {
    image: [Object, File],
    selectedImageIndex: Number,
    planimetryIndex: Number,
    index: Number,
    editable: Boolean,
    isAccommodation: Boolean,
    extension: {
      type: String,
      default: null,
    },

  },
  data() {
    return {
      imagePreview: null,
      confirmDelete: false,
      loading: false,
      confirmPrimary: false,
    }
  },
  beforeMount() {
    this.updateImagePreview(this.image);
  },
  watch: {
    image: {
      immediate: true,
      handler(newImage) {
        this.updateImagePreview(newImage);
      },
    },
  },
  methods: {
    updateImagePreview(image) {
      if (image && this.editable) {
        this.imagePreview = URL.createObjectURL(image);
      } else if (image) {
        this.imagePreview = getAsset(image.pathname);
      } else {
        this.imagePreview = null;
      }
    },
    imageErrorHandler(event) {
      console.error('Image failed to load:', event.target.src);
    },
    deleteImage() {
      this.$emit('delete', this.image.id)
    },
    editImage(){
      this.$emit('edit', this.image.id)
      this.confirmPrimary = false
    },
    selectImage() {
      this.$emit('select', this.index)
    },
    selectPlanimetry() {
      this.$emit('planimetry', this.index)
    },
    removeImage() {
      this.$emit('remove', this.index)
    },
    isVideo(){
      return this.extension!=null && this.extension==='mp4'
    }
  }
}
</script>

<style lang="scss" scoped>

.videoContainer {
  position: relative;
}

.videoContainer video {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  z-index: 0;
}


.image-container {
  position: relative;
  cursor: pointer;
  z-index: 5;
  margin: 20px 0;

  &:hover {
    .hover {
      opacity: 1;
      z-index: 30;
    }
  }
  .hover {
    position: absolute;
    background-color: rgba($black, .4);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: opacity .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .delete-icon {
    position: absolute;
    top: -5px;
    right: -15px;
    background-color: $white;
    border-radius: 50%;
  }
  .set-primary-icon {
    position: absolute;
    top: -5px;
    background-color: $white;
    border-radius: 50%;
  }
  .image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 4px solid transparent;
    width: 100%;
    height: 200px;

    &.selected {
      border: 4px solid $success;
      z-index: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .image-label {
    @include font-style($montserrat, 'medium', $font-12);
    position: absolute;
    color: $white;
    padding: 1px 10px;
    border-radius: 20px;
    width: fit-content;

    &.primary {
      background-color: $success;
      top: -5px;
      left: 0;
    }
    &.planimetry {
      background-color: $dark-blue;
      bottom: -7.5px;
      right: 0;
    }
  }
  .confirm-delete {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
  }
}
</style>
