<template>
  <div class="admin-image-list" :style="adminImagesStyle">
    <div class="images-container" v-if="!editable">
      <draggable
        :list="images"
        ghost-class="ghost"
        @start="dragging = true"
        @end="onDragEnd"
        class="row mx-3"
        @change="handleReorder"
      >
        <b-col cols="4" v-for="(image, index) in images" :key="image.id">
          <AdminImage :selected-image-index="getPrimaryImage(image, index)" :planimetry-index="getPlanimetryImage(image, index)"  :index="index" :image="image" :editable="editable" :extension="getExtension(image)"        @delete="deleteImage" @edit="editImage" />
        </b-col>
      </draggable>
    </div>
    <div class="images-container" v-else>
      <draggable
        :list="editableImages"
        ghost-class="ghost"
        @start="dragging = true"
        @end="onDragEnd"
        class="row mx-3"
        @change="handleReorder"
      >
        <b-col cols="4" v-for="(editableImage, index) in editableImages" :key="editableImage.id">
          <AdminImage :is-accommodation="isAccommodation" :selected-image-index="selected" :planimetry-index="planimetry" :index="index" :image="editableImage" :editable="editable" :extension="getExtension(editableImage)"          @remove="removeImage" @select="selected = $event" @planimetry="planimetry = $event"/>
        </b-col>
      </draggable>
    </div>
    <div class="upload-container" v-if="editable && (limit ? editableImages.length < limit : true)">
      <RFFile label="Add Image" :acceptedFiles="getAcceptedFiles()" size="small" background="#5CB86C" :hide-after-upload="false" @files="pushFiles($event,multiple)" @file="pushFiles($event,multiple)" :multiple="multiple"/>
    </div>
  </div>
</template>

<script>
import RFFile from '@/components/forms/RFFile'
import AdminImage from '@/components/admin/AdminImage'
import draggable from "vuedraggable";

export default {
  name: 'AdminImageList',
  components: {
    RFFile,
    AdminImage,
    draggable
  },
  props: {
    images: Array,
    selectedImageIndex: Number,
    selectedPlanimetryIndex: Number,
    editable: {
      type: Boolean,
      default: true,
    },
    roomType: {
      type: Object,
      default: null,
    },
    isAccommodation: {
      type: Boolean,
      default: true,
    },
    limit: {
      type: [Number, Boolean],
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: null,
    },
    acceptVideos: {
      type: Boolean,
      default: false,
    },
    validateFileSizeLimit:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editableImages: [],
      selected: null,
      planimetry: null,
      acceptedFiles:['jpeg', 'jpg', 'png', 'mp4'],
      fileSizeLimit: 100, //In MB
      dragging: false
    }
  },
  computed: {
    adminImagesStyle() {
      return {
        '--min-height': this.minHeight ? this.minHeight : '70vh',
      }
    }
  },
  beforeMount() {
    this.selected = this.selectedImageIndex
    this.planimetry = this.selectedPlanimetryIndex
    this.images.sort((a, b) => a.orderIndex - b.orderIndex)
    if (this.editable) {
      this.editableImages = [...this.images]
    }
  },
  methods: {
    handleReorder(event) {
      const oldIndex = event.moved.oldIndex;
      const newIndex = event.moved.newIndex;

      if (this.selectedImageIndex === oldIndex) {
        this.selectedImageIndex = newIndex;
      } else if (this.selectedImageIndex > oldIndex && this.selectedImageIndex <= newIndex) {
        this.selectedImageIndex--;
      } else if (this.selectedImageIndex < oldIndex && this.selectedImageIndex >= newIndex) {
        this.selectedImageIndex++;
      }

      this.selected = this.selectedImageIndex;
    },
    async deleteImage(idImage) {
      if (!this.roomType) {
        this.$emit('delete', idImage)
      } else {
        this.$emit('delete-room-type', this.roomType.name, idImage, this.roomType.id)
      }
    },
    async editImage(idImage) {
      if (!this.roomType) {
        this.$emit('edit', idImage)
      } else {
        this.$emit('edit-room-type', this.roomType.name, idImage, this.roomType.id)
      }
    },
    getPrimaryImage(image, index) {
      return image.isPrimary ? index : null;
    },
    getPlanimetryImage(image, index) {
      return image.isPlan ? index : null;
    },
    getAcceptedFiles(){
      if(this.acceptVideos){
        return this.acceptedFiles
      }
      return []
    },
    getExtension(image){
      if(image.pathname == null){
        image.pathname = image.name
      }
      return image.pathname.split('.')[1]
    },
    fileSizeValid(file){
      return file.size <= this.fileSizeLimit * 1024 * 1024
    },
    pushFiles(event,multiple){
      let filesToPushValid = true

      // Helper function to add orderIndex to each file
      const addOrderIndex = (fileList) => {
        for (let i = 0; i < fileList.length; i++) {
          fileList[i].orderIndex = this.editableImages.length + i;
        }
      };

      if(multiple){
        if(this.validateFileSizeLimit){
          const fileList = event
          let i = 0
          while(filesToPushValid && i<fileList.length){
            const file = fileList[i]
            if(!this.fileSizeValid(file)){
              filesToPushValid = false
            }
            else{
              i++
            }
          }
        }
        if(filesToPushValid){
          addOrderIndex(event); // Add orderIndex to each file
          this.editableImages.push(...event)
        } else{
          this.$toasted.error(this.$i18n.t(`You have uploaded a file with size not allowed (must be lower or equals than ${this.fileSizeLimit} MB)`));
        }
      }
      else{
        const file = event
        if(this.validateFileSizeLimit && !this.fileSizeValid(file)){
          this.$toasted.error(this.$i18n.t(`You have uploaded a file with size not allowed (must be lower or equals than ${this.fileSizeLimit} MB)`))
        } else {
          file.orderIndex = this.editableImages.length; // Add orderIndex to the file
          this.editableImages.push(file);
        }
      }
    },
    onDragEnd() {
      this.dragging = false
      if(this.editable){
        this.editableImages = this.editableImages.slice(); // Force update
        this.$emit('images', this.editableImages);
      } else{
        this.images = this.images.slice(); // Force update
        this.$emit('images', this.images);
      }
    },
    removeImage(index) {
      this.editableImages.splice(index, 1);
      if (this.selectedImageIndex === index) {
        this.selectedImageIndex = null;
      } else if (this.selectedImageIndex > index) {
        this.selectedImageIndex--;
      }

      if (this.selectedPlanimetryIndex === index) {
        this.selectedPlanimetryIndex = null;
      } else if (this.selectedPlanimetryIndex > index) {
        this.selectedPlanimetryIndex--;
      }

      this.selected = this.selectedImageIndex;
    },
  },
  watch: {
    editableImages: {
      deep: true,
      handler() {
        this.$emit('images', this.editableImages)
      }
    },
    selected: {
      handler() {
        this.$emit('select', this.selected)
      }
    },
    planimetry: {
      handler() {
        this.$emit('planimetry', this.planimetry)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-image-list {
  border: 1px solid $gray;

  .images-container {
    min-height: var(--min-height);
  }
  .upload-container {
    display: flex;
    justify-content: center;
    border-top: 1px solid $gray;
    padding: 5px 0;
  }
}
</style>
